import React, { useState } from "react";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./PopUp.css";


const PopUp = () => {
    const [showPopUp, setShowPopUp] = useState(true);

    const handleClosePopUp = () => {
        setShowPopUp(false);
    };

    return (
<div className={`popup ${showPopUp ? "show" : ""}`}>
    <div className="popup-content">
        <div className="close-button" onClick={() => setShowPopUp(false)}>
    <FontAwesomeIcon icon={faTimes} />
</div>
        <img src="./Apollo_logo.png" alt="paceB" className="img-container" />
<p className="animated-text">
    Introducing paceB! 
</p>

<p className="animated-text-1">
Our inaugural installation at Apollo Hospitals Chennai, Greams Road.
</p>
    </div>
</div>
    );
};


export default PopUp;