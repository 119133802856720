import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
	const navigate = useNavigate();

	const onLogoContainerClick = useCallback(() => {
		navigate("/");
	}, [navigate]);

	const onServicesContainerClick = useCallback(() => {
		navigate("/services");
	}, [navigate]);

	const onTechnologyContainerClick = useCallback(() => {
		navigate("/technology");
	}, [navigate]);

	const onCareersContainerClick = useCallback(() => {
		navigate("/careers");
	}, [navigate]);

	const onContactContainerClick = useCallback(() => {
		navigate("/contact-us");
	}, [navigate]);

	return (
		<div>
			<div className="Footer">
				<div className="Footer_logo">
					<img
						className="Logo_img"
						alt=""
						src="./Footer_logo.png"
						onClick={onLogoContainerClick}
					></img>
				</div>
				<div className="Footer_nav">
					<div className="Footer_column">
						<li className="Footer_Details" onClick={onTechnologyContainerClick}>
							Product
						</li>
						<li className="Footer_Details" onClick={onServicesContainerClick}>
							Services
						</li>
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLScLR-v2vJYy7kz-ip97fCeJxL3SpQCx6YRW1lK2bYe9-Iw6Mg/viewform?usp=sf_link"
							target="_blank"
						>
							<li className="Footer_Details">Careers</li>
						</a>
						<li className="Footer_Details" onClick={onContactContainerClick}>
							Contact us
						</li>
					</div>
					<div className="Footer_column">
						<a href="tel:+91 8056131009" target="_blank">
							<li className="Footer_Details">Mobile : +91 8056131009</li>
						</a>
						<a href="mailto:info@bearlytech.com" target="_blank">
							<li className="Footer_Details">Email: info@bearlytech.com</li>
						</a>
						<a href="https://goo.gl/maps/BpxTppm45ErXtc7NA" target="_blank">
							<li className="Footer_Details">
								Address: 1, Hanumar Koil Street, Nehru Nagar, Chrompet,
								Chennai-600044
							</li>
						</a>
					</div>
					<div className="Footer_column">
						<li className="bold">Follow us:</li>
						<a
							href="https://www.instagram.com/bearly_technovations/"
							target="_blank"
						>
							<li>Instagram</li>
						</a>
						<a
							href="https://www.linkedin.com/company/bearly-technovations-pvt-ltd/"
							target="_blank"
						>
							<li>Linkedin</li>
						</a>
					</div>
				</div>
			</div>
			<p className="rights">©bEarly TechnoVations Pvt. Ltd.</p>
		</div>
	);
};
export default Footer;
