import Carouselsection from "../components/Carouselsection"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const Services = () => {
  return (
    <div className="services2">
      <Navbar/>
      <Carouselsection/>
      <Footer/>
    </div>
  );
};

export default Services;
