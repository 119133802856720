import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {
	const navigate = useNavigate();

	const onLogoContainerClick = useCallback(() => {
		navigate("/");
	}, [navigate]);

	const onServicesContainerClick = useCallback(() => {
		navigate("/services");
	}, [navigate]);

	const onTechnologyContainerClick = useCallback(() => {
		navigate("/technology");
	}, [navigate]);

	const onBlogContainerClick = useCallback(() => {
		navigate("/blog");
	}, [navigate]);

	const onContactContainerClick = useCallback(() => {
		navigate("/contact-us");
	}, [navigate]);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleHamburgerClick = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<div className="navbar">
			<div className="navbar_left">
				<img
					className="navlogo"
					onClick={onLogoContainerClick}
					alt=""
					src="./navlogo.png"
				/>
			</div>
			<div className={`navbar_right ${isMenuOpen ? "open" : ""}`}>
				<li onClick={onTechnologyContainerClick} className="pages">
					Product
				</li>
				<li onClick={onServicesContainerClick} className="pages">
					Services
				</li>
				<li onClick={onBlogContainerClick} className="pages">
					Blog
				</li>
				<a
					href="https://docs.google.com/forms/d/e/1FAIpQLScLR-v2vJYy7kz-ip97fCeJxL3SpQCx6YRW1lK2bYe9-Iw6Mg/viewform?usp=sf_link"
					target="_blank"
				>
					<li className="pages">Careers</li>
				</a>
				<li onClick={onContactContainerClick} className="pages">
					<button className="contact_us_button">
						<h3 className="contact_us_text">Contact us</h3>
					</button>
				</li>
			</div>
			<div
				className={`hamburger ${isMenuOpen ? "open" : ""}`}
				onClick={handleHamburgerClick}
			>
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
			</div>
		</div>
	);
};

export default Navbar;
