import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Vision from "../components/Vision";
import Techsec from "../components/Techsec";
import Servsec from "../components/Servsec";
import Team from "../components/Team";
import Supportedby from "../components/Supportedby";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import PopUp from "../components/PopUp";
const Home = () => {


	return (
		<div className="home">
			<PopUp />
			<Navbar />
			<Banner />
			<Vision />
			<Techsec />
			<Servsec />
			<Gallery />
			<p className="ourteam">OUR TEAM</p>
			<Team />
			<Supportedby />
			<Footer />
		</div>
	);
};

export default Home;
