import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Servsec.css"

const Servsec = () => {
    const navigate = useNavigate();
   
      const onservClick = useCallback(() => {
        navigate("/services");
      }, [navigate]);


    return(
        <div className="servsec">
            <div className="servsecbanner-text">
                <h7>SERVICES</h7>
                <h2>AI And Full Stack Development For Businesses</h2>
                <p>We help businesses across industries achieve their goals with AI and Full Stack Development. Our services can help you optimize operations, enhance productivity, and drive cost efficiencies. We also work with businesses of all sizes, from startups to Fortune 500 companies.</p>
                <button onClick={onservClick}>LEARN MORE</button>
            </div>
            <div className="servsecimage-container">
                <img src="./objectother-17@2x.png" alt="Slide 1" />
            </div>
        </div>
    )
}
export default Servsec;