import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel"
import "./Carouselsection.css"
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CarouselSection = () => {
    
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const handleCarouselSwipe = (event) => {
    event.stopPropagation();
  }
  
    return(
        <div className="carousel-container">
            <Carousel className="carousel" showThumbs={false} showStatus={false} swipeable={false} onSwipeStart={handleCarouselSwipe}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" onClick={onClickHandler} title={label} className="arrow-button-left">
                <FontAwesomeIcon icon="chevron-left" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" onClick={onClickHandler} title={label} className="arrow-button-right">
                <FontAwesomeIcon icon="chevron-right" />
              </button>
            )
          }
        >
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>Manufacturing</h2>
                <p>AI optimized production processes, predictive maintenance, quality control, supply chain optimization, and robotics automation.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-079@2x.png" alt="Slide 1" />
            </div>
        </div>
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>Transport & Logistics</h2>
                <p>AI based route optimization, fleet management, demand forecasting, predictive maintenance for vehicles, and autonomous driving technologies.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-078@2x.png" alt="Slide 1" />
            </div>
        </div>
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>Retail & E-Commerce</h2>
                <p>AI enabled personalized product recommendations, demand forecasting, inventory management, chatbot-based customer service, and visual search.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-077@2x.png" alt="Slide 1" />
            </div>
        </div>
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>Finance</h2>
                <p>AI based fraud detection, risk assessment, algorithmic trading, credit scoring, and chatbot-based customer support.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-076@2x.png" alt="Slide 1" />
            </div>
        </div>
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>Healthcare</h2>
                <p>AI optimized hospital workflows, surgery scheduling, efficient resource management and patient flow prediction to enhance operational efficiency.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-075@2x.png" alt="Slide 1" />
            </div>
        </div>
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>Web Development</h2>
                <p>Your website is the face of your business in the digital realm, and it should leave a lasting impression. Our Full Stack Developers possess a deep understanding of front-end and back-end technologies, enabling us to create stunning, responsive, and scalable websites tailored to your unique business needs.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-074@2x.png" alt="Slide 1" />
            </div>
        </div>
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>App Development</h2>
                <p>In the fast-paced world we live in, mobile apps have become an integral part of our daily lives. From concept to deployment, we handle the entire app development lifecycle, incorporating the latest technologies and industry best practices to deliver exceptional results.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-073@2x.png" alt="Slide 1" />
            </div>
        </div>
        <div className="carousel-slide">
            <div className="banner-text">
                <h2>Database Integration</h2>
                <p>Efficient data management is crucial for businesses to make informed decisions. Our Full Stack Developers have extensive experience in integrating databases into web and mobile applications, ensuring seamless data flow and optimal performance.</p>
            </div>
            <div className="image-container">
                <img src="./objectother-072@2x.png" alt="Slide 2" />
            </div>
        </div>
            </Carousel>
        </div>
    )
}
export default CarouselSection