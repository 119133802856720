import "./Team.css";
const Team = () => {
	return (
		<div className="crew">
			<div className="member_box">
				<a
					href="https://www.linkedin.com/in/sathyanarayananv26/"
					target="_blank"
				>
					<img className="sathya_img" src="./IMG_1998_Original.jpg" alt="" />
				</a>
				<div className="member_details">
					<div className="details_left">
						<h3 className="member_name">SATHYANARAYANAN V</h3>
						<p className="member_des">FOUNDER & CEO</p>
					</div>
				</div>
			</div>
			<div className="member_box">
				<a
					href="https://www.linkedin.com/in/manigandan-nagarajan-santhanakrishnan-755759b/"
					target="_blank"
				>
					<img className="member_img" src="./rectangle-51@2x.png" alt="" />
				</a>
				<div className="member_details">
					<div className="details_left">
						<h3 className="member_name">DR. N.S. MANIGANDAN</h3>
						<p className="member_des">PARTNER</p>
					</div>
				</div>
			</div>
			<div className="member_box">
				<a
					href="https://www.lybrate.com/trichy/doctor/dr-a-veni-neurologist-1"
					target="_blank"
				>
					<img className="member_img_1" src="./veni.png" alt="" />
				</a>
				<div className="member_details">
					<div className="details_left">
						<h3 className="member_name">DR. A. VENI</h3>
						<p className="member_des">NEUROLOGIST</p>
					</div>
				</div>
			</div>
			{/* <div className="member_box">
				<a href="https://www.linkedin.com/in/dasarathg68/" target="_blank">
					<img className="member_img" src="./rectangle-52@2x.png" alt="" />
				</a>
				<div className="member_details">
					<div className="details_left">
						<h3 className="member_name">DASARATH G</h3>
						<p className="member_des">LEAD ENGINEER</p>
					</div>
				</div>
			</div> */}
			<div className="member_box">
				<a
					href="https://www.linkedin.com/in/madhan-jeyaraman-418010107/?originalSubdomain=in"
					target="_blank"
				>
					<img className="member_img" src="./rectangle-53@2x.png" alt="" />
				</a>
				<div className="member_details">
					<div className="details_left">
						<h3 className="member_name">DR. MADHAN JEYARAMAN</h3>
						<p className="member_des">ORTHOPEDICIAN</p>
					</div>
				</div>
			</div>
			<div className="member_box">
				<a
					href="https://www.linkedin.com/in/swami-vangal-ramamurthy-1758966/"
					target="_blank"
				>
					<img className="member_img" src="./rectangle-54@2x.png" alt="" />
				</a>
				<div className="member_details">
					<div className="details_left">
						<h3 className="member_name">S V RAMAMURTHY</h3>
						<p className="member_des">BUSINESS ADVISOR</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Team;
